import { CommonModule, CurrencyPipe } from '@angular/common';
import { Component, Input, SimpleChanges } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'app-card-template',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatCardModule,
    MatFormFieldModule,
    MatButtonModule,
    MatInputModule,
    MatIconModule,
    MatBadgeModule,
    CommonModule,
  CurrencyPipe],
  templateUrl: './card-template.component.html',
  styleUrl: './card-template.component.css'
})
export class CardTemplateComponent {
  @Input() title: string = '';
  @Input() subtitle: string = '';
  @Input() subsubtitle: string = '';
  @Input() description: string = '';
  @Input() image?: string | undefined;
  @Input() actionButtonText: string = 'Action';
  @Input() isEditing: boolean = false;
  @Input() formGroup: FormGroup | null = null;
  @Input() showImage: boolean = true

  // Optional: Inputs to control visibility
  @Input() showContent: boolean = true;

  // Property to hold the CSS class for the title
  titleClass: string = 'title-normal';

  // Define the maximum number of characters before reducing font size
  maxTitleLength: number = 20;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['title']) {
      this.updateTitleClass();
    }
  }

  private updateTitleClass(): void {
    if (this.title.length > this.maxTitleLength) {
      this.titleClass = 'title-small';
    } else {
      this.titleClass = 'title-normal';
    }
  }
}