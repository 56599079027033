import { Injectable } from '@angular/core';
import { SupabaseClient, User } from '@supabase/supabase-js';
import { SupabaseService } from '@mosaicdash/services';
import { Athlete } from '../../models/new-athlete/new-athlete.model';
import { BehaviorSubject, catchError, from, map, Observable, of, switchMap, throwError } from 'rxjs';
import { CardDisplay, CardOptions, Music } from '../../models/new-athlete/new-athlete.model';
import { Social } from '../../models/athlete/athlete.model';
import { AuthenticationService } from '@mosaicdash/services';


@Injectable({
  providedIn: 'root',
})
export class AthleteService {
  private supabase: SupabaseClient;


  // BehaviorSubject to hold the current CardDisplay
  private cardDisplaySubject = new BehaviorSubject<CardDisplay[] | null>(null);
  public cardDisplay$ = this.cardDisplaySubject.asObservable();

  constructor(private supabaseService: SupabaseService,   private authenticationService: AuthenticationService) {
    this.supabase = this.supabaseService.getClient();
  }


  // Fetch athlete data
  getAthlete(athleteId: string) {
    return this.supabase
      .from('athletes')
      .select('name, nickname')
      .eq('id', athleteId)
      .returns<Athlete>()
      .single();
  }

  async getEditable(athleteId: string, userId: string): Promise<boolean> {
    const adminRole = await this.authenticationService.isAdmin(userId)
    if (adminRole) {
      return true
    } else {




      const { data, error } = await this.supabase
        .from('athlete_users')
        .select('edit_access')
        .eq('athlete_id', athleteId)
        .eq('user_id', userId)
        .single();

      if (error) {
        console.error('Error fetching edit access:', error);
        return false;
      }

      return data?.edit_access ?? false;
    }
  }
  getSocial(user: User) {
    return this.supabase
      .from('athlete_data')
      .select(`strava, world_athletics, milesplit, tfrrs, instagram`)
      .eq('id', user.id)
      .returns<Social>()
      .single()
  }

  saveMusic(songTitle: string, songArtist: string) {
    return from(
      this.supabase.rpc('save_music', {
        p_song_title: songTitle,
        p_artist: songArtist
      })
    ).pipe(
      catchError((error) => {
        console.error('Error joining team:', error);
        throw error;
      })
    );
  }
  /* getCardData(user: User) {
     return this.supabase
       .from('athlete_data')
       .select('name, pronunciation, nickname, line1, line2, notes')
       .eq('id', user.id)
       .returns<CardData>()
       .single()
   }
   
   getTeamData(user: User) {
     return this.supabase
       .from('athlete_data')
       .select('team_name, team_mascot, coach, event')
       .eq('id', user.id)
       .returns<TeamData>()
       .single()
   }*/
  getMusic(): Observable<Music[]> {
    return from(
      this.supabase
        .rpc('get_music')
    ).pipe(
      map(response => {
        if (response.error) {
          throw new Error(response.error.message);
        }
        return response.data; // Return data from response
      }),
      catchError(error => {
        // Handle error
        return throwError(() => new Error(error.message));
      }),
    );
  }


  getCardOptions(userId: string): Observable<CardOptions[]> {
    return from(
      this.supabase
        .from('trading_cards')
        .select(`
          id,
          athletes(*),
          teams(*),
          seasons(*),
        `)
        .eq('athlete_id', userId)
        .order('start_date', { referencedTable: 'seasons', ascending: true })
        .returns<CardOptions[]>()
    ).pipe(
      map(response => {
        if (response.error) {
          throw response.error;
        }
        console.log(response.data)
        return response.data as CardOptions[];
      }),
      catchError(error => {
        console.error('Error fetching Card Options:', error);
        return throwError(() => new Error('Failed to fetch Card Options.'));
      })
    );
  }

  // Updated to return a Promise<string[]>
  async getAthleteUser(id: string): Promise<Athlete[]> {
    const { data, error } = await this.supabase
      .from('athlete_users')
      .select('athlete_id(id, name)')
      .eq('user_id', id);

    if (error) {
      throw new Error(error.message);
    }

    // Assuming each record has an athlete_id field
    return data.map((record: any) => record.athlete_id);
  }


  // Fetch CardDisplays as an Observable Array
  getCardDisplays(athleteId: string): Observable<CardDisplay[]> {
    // First, get the athlete_id associated with the user_id///WE SHOULD NOT DO THIS
    /*   return this.getAthleteUser(athleteId).pipe(
       switchMap(athleteUserResponse => {
         if (athleteUserResponse.error) {
           throw athleteUserResponse.error;
         }
        Extract the athlete_id from the response
         const athleteId = athleteUserResponse.data?.[0]?.athlete_id;
         // Prepare an array of IDs to query
         const idsToQuery = [userId];
         if (athleteId) {
           idsToQuery.push(athleteId);
         }*/
    // Now query the trading_cards table using both IDs
    return from(
      this.supabase
        .from('trading_cards')
        .select(`
              *,
              athletes(*),
              teams(*),
              seasons(sport_id(*), start_date, end_date, sport_variation_id(*)),
              trading_card_photos(photos(*))
            `)
        .eq('athlete_id', athleteId)
        .order('teams(name)', { ascending: false }) // Adjust ordering as needed
    ).pipe(
      map(response => {
        if (response.error) {
          throw response.error;
        }
        const data = response.data || [];
        const cardDisplays: CardDisplay[] = data.map(card => ({
          id: card.id,
          athlete_id: card.athlete_id,
          team_id: card.teams,
          season_id: card.seasons,
          card_number: card.card_number,
          description: card.description,
          imageUrl: card.trading_card_photos[0]?.photos.photo_url,
          name: card.athletes.name,
          pronunciation: card.athletes.pronunciation,
          nickname: card.athletes.nickname,
          line1: [
            card.athletes.home_town,
            card.athletes.state
          ].filter(Boolean).join(', '),
          line2: card.team?.name || '',
          team_name: card.teams?.name === 'Unassigned' ? '' : card.teams?.name,
          team_mascot: card.teams?.mascot,
          coach: '', // Implement coach retrieval if applicable
          notes: card.description, // Adjust based on your actual fields
        }));
        this.cardDisplaySubject.next(cardDisplays);
        return cardDisplays;
      }),
      catchError(error => {
        console.error('Error fetching card displays:', error);
        return of([]); // Return an empty array in case of error
      })
    );
  }

  // Update CardDisplay Data
  updateCardDisplayData(updatedCardDisplay: CardDisplay): Observable<any> {// need to rewrite this
    return from(
      this.supabase
        .from('trading_cards')
        .update({
          name: updatedCardDisplay.name,
          pronunciation: updatedCardDisplay.pronunciation,
          nickname: updatedCardDisplay.nickname,
          line1: updatedCardDisplay.line1,
          line2: updatedCardDisplay.line2,
          notes: updatedCardDisplay.notes,
          team_name: updatedCardDisplay.team_name,
          team_mascot: updatedCardDisplay.team_mascot,
          coach: updatedCardDisplay.coach,
          imageUrl: updatedCardDisplay.imageUrl
        })
        .eq('id', updatedCardDisplay.id)
    ).pipe(
      map(response => {
        if (response.error) {
          throw response.error;
        }
        // Update the BehaviorSubject with the new data
        return response.data;
      }),
      catchError(error => {
        console.error('Error updating card display data:', error);
        throw error;
      })
    );
  }



  // Update card display data
  /* updateCardDisplayData(cardDisplay: CardDisplay) {
     const updatedCardDisplay = {
       ...cardDisplay,
       updated_at: new Date(),
     };
   
     return this.supabase.from('athlete_data').upsert(updatedCardDisplay);
   }*/

  getCardTempDisplay(tempID: string) {
    return this.supabase
      .from('nonuser_athlete_data')
      .select('name, pronunciation, nickname, line1, line2, notes, team_name, team_mascot, coach')
      .eq('id', tempID)
      .returns<CardDisplay>()
      .single()
  }

  updateCardPhotoUrl(cardId: string, uploadedUrl: string): Observable<any> {
    return from(
      this.supabase.rpc('update_trading_card_photo', {
        p_card_id: cardId,
        p_photo_url: uploadedUrl,
      })
    ).pipe(
      map(response => {
        if (response.error) {
          throw response.error;
        }
        // Optionally update the BehaviorSubject with the new photo URL
        /* const currentCard = this.cardDisplaySubject.getValue();
        if (currentCard) {
          currentCard.photoUrl = uploadedUrl;
          this.cardDisplaySubject.next(currentCard);
        } */
        return response.data;
      }),
      catchError(error => {
        console.error('Error updating card photo URL:', error);
        throw error;
      })
    );
  }

  // Update social links
  updateSocial(social: Social) {
    const updatedSocial = {
      ...social,
      updated_at: new Date(),
    };

    return this.supabase.from('athlete_data').upsert(updatedSocial);
  }

  /*
  
  getCardDisplay(userID: string) {
  return this.supabase
    .from('athlete_data')
    .select('name, pronunciation, nickname, line1, line2, notes, team_name, team_mascot, coach, imageUrl')
    .eq('id', userID)
    .returns<CardDisplay>()
    .single()
  }*/
  /*
    updateTeamData(teamData: TeamData) {
      const updateTeamData = {
        ...teamData,
        updated_at: new Date(),
      }
   
      return this.supabase.from('athlete_data').upsert(updateTeamData)
    }
   
    // Update card data
    updateCardData(cardData: CardData) {
      const updatedCardData = {
        ...cardData,
        updated_at: new Date(),
      };
   
      return this.supabase.from('athlete_data').upsert(updatedCardData);
    }
   
   */

  /* updateCardDataFromCard(user: boolean, idNum: string, value: number, newValue: string) {//0 name 1 pronunciation 2 nickname 3 line1 4 line2 5 team 6 mascot 7 coach 8 cardback
     var update
     var table = ""
     if (user) {
       table = "athlete_data"
     } else {
       table = "nonuser_athlete_data"
     }
     if (value == 0) {
       update = {
         id: idNum,
         name: newValue,
         updated_at: new Date()
       }
     }
   
     if (value == 1) {
       update = {
         id: idNum,
         pronunciation: newValue,
         updated_at: new Date()
       }
     }
   
     if (value == 2) {
       update = {
         id: idNum,
         nickname: newValue,
         updated_at: new Date()
       }
     }
   
     if (value == 3) {
       update = {
         id: idNum,
         line1: newValue,
         updated_at: new Date()
       }
     }
   
     if (value == 4) {
       update = {
         id: idNum,
         line2: newValue,
         updated_at: new Date()
       }
     }
   
     if (value == 5) {
       update = {
         id: idNum,
         team_name: newValue,
         updated_at: new Date()
       }
     }
   
     if (value == 6) {
       update = {
         id: idNum,
         team_mascot: newValue,
         updated_at: new Date()
       }
     }
   
     if (value == 7) {
       update = {
         id: idNum,
         coach: newValue,
         updated_at: new Date()
       }
     }
   
     if (value == 8) {
       update = {
         id: idNum,
         notes: newValue,
         updated_at: new Date()
       }
     }
   
     return this.supabase.from(table).upsert(update)
   }
   
   // Generate temporary athlete
   generateTempAthlete(cardDisplay: CardDisplay) {
     const newAthlete = {
       ...cardDisplay,
       updated_at: new Date(),
     };
   
     return this.supabase
       .from('nonuser_athlete_data')
       .insert(newAthlete)
       .select('id')
       .single();
   }
   
   // Add competitor
   addCompetitor(competitor: Competitors) {
     const newCompetitor = {
       ...competitor,
       updated_at: new Date(),
     };
   
     return this.supabase.from('competitors').insert(newCompetitor);
   }
  */
  viewAllUsers() {
    return this.supabase
      .from('athlete_data')
      .select('id, event')
      .order('event', { ascending: true })
  }

  // Additional athlete-related methods...
}