import { Component, Inject, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AdditionalField, Role, RoleWithDetails } from '@mosaicdash/models';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_NATIVE_DATE_FORMATS,
  MatNativeDateModule,
  NativeDateAdapter,
} from '@angular/material/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AuthenticationService } from '@mosaicdash/services';
import { UserService } from '../../../services/user/user.service';
import { Country, State } from '../../../models/location/location.model';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-role-selection-dialog',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatButtonModule,
    MatInputModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
  ],
  providers: [
    { provide: DateAdapter, useClass: NativeDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: MAT_NATIVE_DATE_FORMATS },
  ],
  templateUrl: './role-selection-dialog.component.html',
  styleUrl: './role-selection-dialog.component.css',
})
export class RoleSelectionDialogComponent implements OnInit {
  // Step management
  currentStep: number = 1;

  // Step 1: Role Selection
  rolesForm: FormGroup;
  availableRoles: Role[] = [
    {
      name: 'Athlete',
      requires_additional_data: true,
      information: 'Select If You Are Creating Your Own Profile',
    },
    // { name: 'Parent', requires_additional_data: true, information: 'Select If You Are Creating Your Child\'s/Children\'s Profile(s)' },
    //  { name: 'Event Worker', requires_additional_data: true, information: 'Select If You Are Interested In Working at Events' },
    {
      name: 'Official',
      requires_additional_data: true,
      information: 'Select If You Are Interested In Officiating at Events',
    },
  ];

  // Step 2: Additional Data Collection
  additionalDataForm: FormGroup;
  roleDetailsMap: { [key: string]: AdditionalField[] } = {
    Athlete: [
      { name: 'name', type: 'text', label: 'Name', validation: 'required' },
      {
        name: 'pronunciation',
        type: 'text',
        label: 'Pronunciation',
        validation: 'required',
      },
      {
        name: 'nickname',
        type: 'text',
        label: 'Nickname',
        validation: 'required',
      },
      {
        name: 'gender',
        type: 'select',
        label: 'Gender',
        options: ['Male', 'Female', 'Nonbinary'],
        validation: 'required',
      },
      {
        name: 'date_of_birth',
        type: 'date',
        label: 'Date of Birth',
        validation: 'required',
      },
      {
        name: 'state',
        type: 'select',
        label: 'State',
        options: [],
        validation: 'required',
      },
      {
        name: 'home_town',
        type: 'text',
        label: 'Home Town',
        validation: 'required',
      },
      {
        name: 'citizen_country',
        type: 'select',
        label: 'Athlete Country',
        options: [],
        validation: 'required',
      },
    ],
    Parent: [
      {
        name: 'name',
        type: 'text',
        label: 'Your Name',
        validation: 'required',
      },
      {
        name: 'pronunciation',
        type: 'text',
        label: 'Your Name Pronunciation',
        validation: 'required',
      },
      {
        name: 'create_number',
        type: 'select',
        label: 'Number of Athletes to Create',
        options: ['1', '2', '3', '4'],
        validation: 'required',
      },
      /*{ name: 'name', type: 'text', label: 'Name', validation: 'required' },
      { name: 'pronunciation', type: 'text', label: 'Pronunciation', validation: 'required' },
      { name: 'nickname', type: 'text', label: 'Nickname', validation: 'required' },
      { name: 'date_of_birth', type: 'date', label: 'Date of Birth', validation: 'required' },
      { name: 'state', type: 'select', label: 'State', options: ['Full', 'Limited'], validation: 'required' },
      { name: 'home_town', type: 'text', label: 'Home Town', validation: 'required' },
      { name: 'citizen_country', type: 'select', label: 'Athlete Country', options: ['Full', 'Limited'], validation: 'required' },*/
    ],
    'Event Worker': [
      { name: 'name', type: 'text', label: 'Name', validation: 'required' },
      {
        name: 'pronunciation',
        type: 'text',
        label: 'Pronunciation',
        validation: 'required',
      },
      {
        name: 'contact_email',
        type: 'text',
        label: 'Contact Email',
        validation: 'required',
      },
      {
        name: 'contact_phone',
        type: 'text',
        label: 'Contact Phone',
        validation: 'required',
      },
      {
        name: 'date_of_birth',
        type: 'date',
        label: 'Date of Birth',
        validation: 'required',
      },
      { name: 'city', type: 'text', label: 'City', validation: 'required' },
      {
        name: 'state',
        type: 'select',
        label: 'State',
        options: [],
        validation: 'required',
      },
    ],

    Official: [
      { name: 'name', type: 'text', label: 'Name', validation: 'required' },
      {
        name: 'pronunciation',
        type: 'text',
        label: 'Pronunciation',
        validation: 'required',
      },
      {
        name: 'contact_email',
        type: 'text',
        label: 'Contact Email',
        validation: 'required',
      },
      {
        name: 'contact_phone',
        type: 'text',
        label: 'Contact Phone',
        validation: 'required',
      },
      {
        name: 'date_of_birth',
        type: 'date',
        label: 'Date of Birth',
        validation: 'required',
      },
      { name: 'city', type: 'text', label: 'City', validation: 'required' },
      {
        name: 'state',
        type: 'select',
        label: 'State',
        options: [],
        validation: 'required',
      },
      {
        name: 'usatf_certified',
        type: 'select',
        label: 'Usatf Certified',
        options: [
          { code: true, name: 'Yes' },
          { code: false, name: 'No' },
        ],
        validation: 'required',
      },
      {
        name: 'usatf_number',
        type: 'text',
        label: 'USATF Number',
        validation: '',
      },
    ],
    // 'Event Worker' and 'Official' do not require additional data
  };

  selectedRoles: Role[] = [];
  states: State[] = [];
  countries: Country[] = [];
  isLoadingStates: boolean = false;
  isLoadingCountries: boolean = false;
  loadStatesError: string | null = null;
  loadCountriesError: string | null = null;

  fieldOptionsMap: {
    [roleName: string]: {
      [fieldName: string]: { code: string; name: string }[];
    };
  } = {};
  existingRoles: any[] = []; // Store existing roles

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<RoleSelectionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBar: MatSnackBar,
    private authService: AuthenticationService,
    private userService: UserService
  ) {
    // Initialize forms
    this.rolesForm = this.fb.group({
      roles: this.fb.array([], Validators.required),
    });

    this.additionalDataForm = this.fb.group({});
    // Assign existing roles from data
    if (data && data.existingRoles) {
      this.existingRoles = data.existingRoles;
    }
  }

  ngOnInit(): void {
    this.markAssignedRoles();
    this.addRolesCheckboxes();
    this.fetchStates();
    this.fetchCountries();
  }

  private addRolesCheckboxes() {
    this.availableRoles.forEach((role) => {
      const control = this.fb.control({
        value: false,
        disabled: role.alreadyAssigned!,
      });
      (this.rolesForm.controls['roles'] as FormArray).push(control);
    });
  }

  get rolesFormArray() {
    return this.rolesForm.get('roles') as FormArray;
  }

  proceedToAdditionalData() {
    const rolesFormArray = this.rolesForm.get('roles') as FormArray;
    const selectedRolesNames = rolesFormArray.controls
      .map((control, index) => {
        const role = this.availableRoles[index];
        // Only consider enabled controls (i.e., roles not already assigned)
        if (control.enabled && control.value) {
          return role.name;
        }
        return null;
      })
      .filter((v: string | null) => v !== null);
  
    console.log('Selected Roles Names:', selectedRolesNames); // Debugging
  
    if (selectedRolesNames.length === 0) {
      this.snackBar.open('Please select at least one role.', 'Close', {
        duration: 3000
      });
      return;
    }
  
    // Filter selected roles that require additional data
    this.selectedRoles = this.availableRoles.filter(role => selectedRolesNames.includes(role.name));
  
    console.log('Selected Roles with Details:', this.selectedRoles); // Debugging
  
    const rolesRequiringData = this.selectedRoles.filter(role => role.requires_additional_data);
  
    console.log('Roles Requiring Additional Data:', rolesRequiringData); // Debugging
  
    if (rolesRequiringData.length > 0) {
      // Build additional data form
      this.buildAdditionalDataForm(rolesRequiringData);
      this.currentStep = 2;
      console.log('Current Step:', this.currentStep); // Debugging
    } else {
      // No additional data required, close dialog with selected roles
      const result: RoleWithDetails[] = this.selectedRoles.map(role => ({
        roleName: role.name,
        details: {}
      }));
      this.dialogRef.close(result);
    }
  }
  private buildAdditionalDataForm(roles: Role[]) {
    roles.forEach((role) => {
      const fields = this.roleDetailsMap[role.name];
      if (fields) {
        // Initialize the options map for this role
        this.fieldOptionsMap[role.name] = {};

        fields.forEach((field) => {
          if (role.name === 'Parent' && field.name === 'create_number') {
            const control = this.fb.control('', Validators.required);
            this.additionalDataForm.addControl(
              `${role.name}_${field.name}`,
              control
            );

            // Initialize FormArray for athletes
            const athletesArray = this.fb.array([]);
            this.additionalDataForm.addControl(
              `${role.name}_athletes`,
              athletesArray
            );

            // Listen to changes in 'create_number' to dynamically add/remove athlete forms
            control.valueChanges.subscribe((value: string | null) => {
              if (value !== null) {
                // Handle null case
                const num = parseInt(value, 10);
                if (!isNaN(num)) {
                  this.adjustAthleteForms(role.name, num);
                }
              }
            });

            // Precompute options for 'create_number' if necessary
            if (field.type === 'select') {
              this.fieldOptionsMap[role.name][field.name] =
                this.getOptionsForField(role.name, field.name);
            }
          } else {
            let control: FormControl;

            // Define validators
            const validators = [];
            if (field.validation === 'required') {
              validators.push(Validators.required);
            }

            // Initialize the control
            control = this.fb.control('', validators);

            // Add control to the form
            this.additionalDataForm.addControl(
              `${role.name}_${field.name}`,
              control
            );

            // Precompute options for select fields
            if (field.type === 'select') {
              this.fieldOptionsMap[role.name][field.name] =
                this.getOptionsForField(role.name, field.name);
            }
          }
        });
      }
    });
  }

  private adjustAthleteForms(roleName: string, numberOfAthletes: number) {
    const athletesArray = this.additionalDataForm.get(
      `${roleName}_athletes`
    ) as FormArray;
    const athleteFields = this.roleDetailsMap['Athlete'];

    while (athletesArray.length < numberOfAthletes) {
      const athleteGroup = this.fb.group({});
      athleteFields.forEach((field) => {
        let control: FormControl;
        const validators =
          field.validation === 'required' ? [Validators.required] : [];
        control = this.fb.control('', validators);
        athleteGroup.addControl(field.name, control);

        // Precompute options for select fields
        if (field.type === 'select') {
          if (!this.fieldOptionsMap['Athlete']) {
            this.fieldOptionsMap['Athlete'] = {};
          }
          if (!this.fieldOptionsMap['Athlete'][field.name]) {
            this.fieldOptionsMap['Athlete'][field.name] =
              this.getOptionsForField('Athlete', field.name);
          }
        }
      });
      athletesArray.push(athleteGroup);
    }

    // Remove excess athlete forms
    while (athletesArray.length > numberOfAthletes) {
      athletesArray.removeAt(athletesArray.length - 1);
    }
  }

  submitAdditionalData() {
    if (this.additionalDataForm.invalid) {
      this.snackBar.open('Please fill all required fields.', 'Close', {
        duration: 3000,
      });
      return;
    }

    // Ensure the user is authenticated
    const currentUser = this.authService.getCurrentUser(); // Implement this method in AuthService
    if (!currentUser) {
      this.snackBar.open(
        'You must be logged in to perform this action.',
        'Close',
        {
          duration: 3000,
        }
      );
      return;
    }

    // Prepare the result data
    const result: RoleWithDetails[] = this.selectedRoles.map((role) => {
      const details: { [key: string]: any } = {};

      if (role.requires_additional_data) {
        const fields = this.roleDetailsMap[role.name];
        fields.forEach((field) => {
          if (role.name === 'Parent' && field.name === 'create_number') {
            const createNumber = this.additionalDataForm.get(
              `${role.name}_${field.name}`
            )?.value;
            details['create_number'] = createNumber;

            // Collect athlete details
            const athletesArray = this.additionalDataForm.get(
              `${role.name}_athletes`
            ) as FormArray;
            details['athletes'] = athletesArray.controls.map(
              (control: AbstractControl) => {
                const group = control as FormGroup;
                const athleteDetails: { [key: string]: any } = {};
                Object.keys(group.controls).forEach((key) => {
                  athleteDetails[key] = group.get(key)?.value;
                });
                return athleteDetails;
              }
            );
          } else {
            const fieldValue = this.additionalDataForm.get(
              `${role.name}_${field.name}`
            )?.value;
  
            // Convert 'usatf_certified' to boolean if necessary
   console.log(fieldValue)
          // Convert 'usatf_certified' to boolean if necessary
          if (field.name === 'usatf_certified') {
            if (typeof fieldValue === 'string') {
              // Handle string representations
              details[field.name] = fieldValue.toLowerCase() === 'true';
            } else {
              // Assume it's already a boolean
              details[field.name] = Boolean(fieldValue);
            }
          } else {
            details[field.name] = fieldValue;
          }
        }
      });
      }
      return {
        roleName: role.name,
        details: details,
      };
    });

    // Call the UserService to create the user
    this.userService.createUser(result).subscribe({
      next: (userId: string) => {
        this.snackBar.open('Role added successfully!', 'Close', {
          duration: 3000,
        });
        this.dialogRef.close({ userId, roles: result });
      },
      error: (error: any) => {
        this.snackBar.open(`Error: ${error.message}`, 'Close', {
          duration: 5000,
        });
      },
    });
  }

  backToRoleSelection() {
    this.currentStep = 1;
  }

  cancel() {
    this.dialogRef.close(null);
  }

  // Helper to get athlete FormArray
  getAthletesArray(roleName: string): FormArray {
    return this.additionalDataForm.get(`${roleName}_athletes`) as FormArray;
  }

  private fetchStates() {
    this.isLoadingStates = true;
    this.userService.getStates().subscribe({
      next: (states: State[]) => {
        this.states = states;
        this.isLoadingStates = false;
      },
      error: (error: any) => {
        this.loadStatesError = error.message;
        this.snackBar.open(`Failed to load states: ${error.message}`, 'Close', {
          duration: 5000,
        });
        this.isLoadingStates = false;
      },
    });
  }

  /**
   * Fetch countries from the LocationService
   */
  private fetchCountries() {
    this.isLoadingCountries = true;
    this.userService.getCountries().subscribe({
      next: (countries: Country[]) => {
        this.countries = countries;
        this.isLoadingCountries = false;
      },
      error: (error: any) => {
        this.loadCountriesError = error.message;
        this.snackBar.open(
          `Failed to load countries: ${error.message}`,
          'Close',
          {
            duration: 5000,
          }
        );
        this.isLoadingCountries = false;
      },
    });
  }

  private updateFieldOptions(
    fieldName: string,
    options: { code: string; name: string }[]
  ) {
    // Update the options for all roles that have this field
    Object.keys(this.fieldOptionsMap).forEach((roleName) => {
      if (this.fieldOptionsMap[roleName][fieldName]) {
        this.fieldOptionsMap[roleName][fieldName] = options;
      }
    });

    // If you have dynamically created athlete forms, update their options as well
    if (fieldName === 'state' || fieldName === 'citizen_country') {
      this.updateAthleteFieldOptions(fieldName, options);
    }
  }

  private updateAthleteFieldOptions(
    fieldName: string,
    options: { code: string; name: string }[]
  ) {
    // Ensure the field options map for 'Athlete' exists
    if (!this.fieldOptionsMap['Athlete']) {
      this.fieldOptionsMap['Athlete'] = {};
    }
    this.fieldOptionsMap['Athlete'][fieldName] = options;
  }

  /**
   * Returns the options for a field based on predefined options or dynamic data
   */
  private getOptionsForField(
    roleName: string,
    fieldName: string
  ): { code: string; name: string }[] {
    // Check if the field has predefined options
    const field = this.roleDetailsMap[roleName].find(
      (f) => f.name === fieldName
    );
    if (field && field.options && field.options.length > 0) {
      // If options are strings, map them to objects with code and name
      return field.options.map((option) => {
        if (typeof option === 'string') {
          return { code: option, name: option };
        } else {
          // If options are objects with code and name
          return { code: option.code, name: option.name };
        }
      });
    }

    // Handle dynamic fields
    if (fieldName.toLowerCase().includes('state')) {
      return this.states;
    } else if (fieldName.toLowerCase().includes('country')) {
      return this.countries;
    } else {
      // Handle other predefined fields like 'usatf_certified'
      if (fieldName === 'usatf_certified') {
        return [
          { code: 'true', name: 'Yes' },
          { code: 'false', name: 'No' },
        ];
      } else {
        return [];
      }
    }
  }
  private markAssignedRoles() {
    if (this.data && this.data.existingRoles) {
      this.existingRoles = this.data.existingRoles;
    }

    this.availableRoles = this.availableRoles.map((role) => {
      const alreadyAssigned = this.existingRoles.some(
        (r) => r.roles.name.toLowerCase() === role.name.toLowerCase()
      );
      console.log(this.existingRoles)
      console.log(`Role: ${role.name}, Already Assigned: ${alreadyAssigned}`); // Debugging
      return { ...role, alreadyAssigned: alreadyAssigned };
    });
  }
}
