
<div *ngIf="imageUrl" class="cropper-wrapper">
  <img
    #imageElement
    [src]="imageUrl"
    (load)="initializeCropper()"
    alt="Source Image"
  />
</div>

<div class="file-input-wrapper">
  <input
    type="file"
    #fileInput
    (change)="onFileSelected($event)"
    accept="image/*"
    style="display: none;"
  />
  <button mat-button color="primary" (click)="triggerFileInput()">
    Select Image
  </button>
</div>
<!--
<div *ngIf="imageUrl" class="cropper-actions">
  <button mat-button color="primary" (click)="cropImage()">Save</button>
  <button mat-button color="warn" (click)="cancelCrop()">Cancel</button>
</div>-->