import { Injectable } from '@angular/core';
import { SupabaseClient } from '@supabase/supabase-js';
import { SupabaseService } from '@mosaicdash/services';
import { catchError, from, map, Observable, throwError } from 'rxjs';
import { RoleWithDetails } from '../../../../../../libs/models/src/lib/models/user/user.model';
import { Country, State } from '../../models/location/location.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private supabase: SupabaseClient;

  constructor(private supabaseService: SupabaseService) {
    this.supabase = this.supabaseService.getClient();
  }

  async isAthlete(): Promise<boolean> {
    const { count } = await this.supabase
      .from('athlete_users')
      .select('*', { count: 'exact', head: true })
    if (count! > 0) {
      return true
    } else return false

  }

  //async lastPopUp make this and a new database


  createUser(rolesWithDetails: RoleWithDetails[]): Observable<string> {
    // Prepare the payload for the RPC
    const payload = rolesWithDetails.map(role => ({
      role_name: role.roleName,
      details: role.details
    }));

    // Call the Supabase RPC function
    return from(
      this.supabase
        .rpc('create_user_with_roles', { roles_payload: payload as unknown as JSON })
        .then(response => {
          if (response.error) {
            throw response.error;
          }
          return response.data as { user_id: string };
        })
    ).pipe(
      map(data => data.user_id),
      catchError(error => throwError(() => new Error(error.message)))
    );
  }

  getStates(): Observable<State[]> {
    return from(
      this.supabase
        .from('states_and_territories')
        .select('*')
        .order('name', { ascending: true }) // Optional: Order alphabetically
    ).pipe(
      map(response => {
        if (response.error) {
          throw response.error;
        }
        return response.data as State[];
      }),
      catchError(error => throwError(() => new Error(error.message)))
    );
  }

  /**
   * Fetch all countries.
   * @returns Observable of Country array
   */
  getCountries(): Observable<Country[]> {
    return from(
      this.supabase
        .from('countries')
        .select('*')
        .order('name', { ascending: true }) // Optional: Order alphabetically
    ).pipe(
      map(response => {
        if (response.error) {
          throw response.error;
        }
        let countries = response.data as Country[];

        // Find the index of "United States"
        const usIndex = countries.findIndex(country => country.code.toLowerCase() === 'usa' || country.name.toLowerCase() === 'united states');

        if (usIndex > -1) {
          // Remove "United States" from its current position
          const usCountry = countries.splice(usIndex, 1)[0];
          // Add "United States" at the beginning of the array
          countries.unshift(usCountry);
        }

        return countries;
      }),
      catchError(error => {
        //this.countriesCache$ = null; // Reset cache on error
        return throwError(() => new Error(error.message));
      }),
      //shareReplay(1) // Cache the latest emitted value
    );
  }
  //return this.countriesCache$;
}

