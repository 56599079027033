import { Injectable } from '@angular/core';
import { createClient, SupabaseClient } from '@supabase/supabase-js';
import { environment } from '../../../../../../apps/mosaic-sports/src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SupabaseService {
  private readonly supabase: SupabaseClient;

  constructor() {
    this.supabase = createClient(
      environment.supabaseUrl, // Replace with your Supabase URL
      environment.supabaseKey // Replace with your Supabase Anon/Public Key
    );
  }

  // Getter to access the Supabase client
  getClient(): SupabaseClient {
    return this.supabase;
  }
}


/*import { Injectable } from '@angular/core';

import {
  AuthChangeEvent,
  AuthSession,
  createClient,
  Session,
  SupabaseClient,
  User,
} from '@supabase/supabase-js'
import { environment } from '../../../environments/environment'

export interface Profile {
  id?: string
  full_name: string
}

export interface CardPhoto {
  id?: string
  cardPhoto_url: string
}

export interface Social {
  id?: string
  strava: string
  world_athletics: string
  milesplit: string
  tfrrs: string
  instagram: string
}

export interface EnteredEvents {
  event_name: string
  event_number: number
}

export interface EnterableEvents {
  event_name: string
  event_number: number
  id: string
}

export interface SubEventID {
  sub_event: string
  id: string
}

export interface SubEventid {
  id: string
}

export interface CardData {
  id?: string
  name: string
  pronunciation: string
  nickname: string
  line1: string
  line2: string
  notes: string
}

export interface CardDisplay {
  id?: string
  name: string
  pronunciation: string
  nickname: string
  line1: string
  line2: string
  notes: string
  team_name: string
  team_mascot: string
  coach: string
}

export interface TeamData {
  id?: string
  team_name: string
  team_mascot: string
  coach: string
  event: string
}

export interface Athlete {
  id?: string
  name: string
  nickname: string
  strava: string
  world_athletics: string
  milesplit: string
  tfrrs: string
  instagram: string
}

export interface Event {
  id?: string
  event_name: string
  start_date: Date
  end_date: Date
  sport: string
  venue_id: string
}


export interface SubEvent {
  id: string
  event_name: string
  event_number: number
  scheduled_time: string
  sponsor_id: string
  location: string
  field_event: boolean
  final: boolean
  relay: boolean
}

export interface Venue {
  id?: string
  venue_name: string
  country: string
  administrative_area: string
  locality: string
  postal_code: string
  thoroughfare: string
  premise: string
}

export interface Competitors {
  id?: string
  hip_number: number
  bib_number: string
  scheduled_athlete: string
  athlete_id: string
  nonuser_id: string
  seed: number
  result: number
  heat_number: number
  sub_event: string
  scheduled_team: string
}

export interface AllUsers {
  id: string
  event: string
}

export interface Sponsor {
  name: string;
  website: string;
}

export interface SeriesInfo {
  name: string
  description: string
  sponsor_id: string
}

export interface EventInfo {
  id: string
  event_name: string
  start_date: string
  end_date: string
  venue_id: string
  sport: string
  sponsors: string
  timers_id: string
  live_results: string
  final_results: string
  open_price: string
}

export interface SubEventInfo {
id: string
event_id: string 
scheduled_time: string 
event_name: string
event_number: string 
sponsor_id: string 
location: string 
field_event: boolean 
final: boolean 
relay: boolean
athletes?: Athletes[];
}

export interface Athletes {
  id: string;
  name: string;
  dob: Date;
  gender: string;
  email: string;
  team: string;
  seedMark: string;
  aau: string;
  usatf: string;
}


@Injectable({
  providedIn: 'root'
})
export class SupabaseService {
  private supabase: SupabaseClient
  _session: AuthSession | null = null

  constructor() {
    this.supabase = createClient(environment.supabaseUrl, environment.supabaseKey)
  }

  get session() {
    this.supabase.auth.getSession().then(({ data }) => {
      this._session = data.session
    })
    return this._session
  }

  profile(user: User) {
    return this.supabase
      .from('user_data')
      .select(`full_name`)
      .eq('id', user.id)
      .single()
  }

  social(user: User) {
    return this.supabase
      .from('athlete_data')
      .select(`strava, world_athletics, milesplit, tfrrs, instagram`)
      .eq('id', user.id)
      .single()
  }

  cardPhoto(user: User) {
    return this.supabase
      .from('athlete_data')
      .select('cardPhoto_url')
      .eq('id', user.id)
      .single()
  }

  cardData(user: User) {
    return this.supabase
      .from('athlete_data')
      .select('name, pronunciation, nickname, line1, line2, notes')
      .eq('id', user.id)
      .single()
  }

  teamData(user: User) {
    return this.supabase
      .from('athlete_data')
      .select('team_name, team_mascot, coach, event')
      .eq('id', user.id)
      .single()
  }

  cardDisplay(userID: string) {
    return this.supabase
      .from('athlete_data')
      .select('name, pronunciation, nickname, line1, line2, notes, team_name, team_mascot, coach')
      .eq('id', userID)
      .single()
  }

  cardTempDisplay(tempID: string) {
    return this.supabase
      .from('nonuser_athlete_data')
      .select('name, pronunciation, nickname, line1, line2, notes, team_name, team_mascot, coach')
      .eq('id', tempID)
      .single()
  }

  athlete(userID: string) {
    return this.supabase
      .from('athlete_data')
      .select('name, nickname, strava, world_athletics, milesplit, tfrrs, instagram')
      .eq('id', userID)
      .single()
  }

  event(eventID: string) {
    return this.supabase
      .from('events')
      .select('event_name, start_date, end_date, sport, venue_id')
      .eq('id', eventID)
  }

  subEvents(eventID: string[]) {
    return this.supabase
      .from('sub_events')
      .select('id, event_id, scheduled_time, event_name, event_number, sponsor_id, location, field_event, final, relay')
      .in('event_id', eventID) //Fetches multiple records where the column value matches any of the values in the provided array.
      .order('event_number', { ascending: true })
  }

  competitors(subEventID: string) {
    return this.supabase
      .from('competitors')
      .select('id, hip_number, bib_number, scheduled_athlete, athlete_id, nonuser_id, seed, result, heat_number, scheduled_team, sub_event')
      .eq('sub_event', subEventID)
      .order('heat_number', { ascending: true })
      .order('hip_number', { ascending: true })
  }

  viewAllUsers() {
    return this.supabase
      .from('athlete_data')
      .select('id, event')
      .order('event', { ascending: true })
  }

  venue(venueID: string) {
    return this.supabase
      .from('venues')
      .select('venue_name, country, administrative_area, locality, postal_code, thoroughfare, premise')
      .eq('id', venueID)
      .single()
  }

  enteredSubEvents(athleteID: string) {
    return this.supabase
      .from('competitors')
      .select('sub_event, id')
      .eq('athlete_id', athleteID)
  }

  seriesInfo(seriesID: string)
  {
    return this.supabase
    .from('meet_series')
    .select('name, description,sponsor_id')
    .eq('id', seriesID)
    .single()
  }

  sponsorInfo(sponsorID: string)
  {
    return this.supabase
    .from('sponsors')
    .select('name, website')
    .eq('id', sponsorID)
    .single()
  }

  seriesEvents(seriesID: string)
  {
    return this.supabase
    .from('events')
    .select('id')
    .eq('series_id', seriesID)
  }

  eventInfo(eventID: string[])
  {
    return this.supabase
    .from('events')
    .select('id, event_name, start_date, end_date, venue_id, sport, sponsors, timers_id, live_results, final_results, open_price')
    .in('id', eventID)
  }

  enterableSubEvents(eventID: string) {
    return this.supabase
      .from('sub_events')
      .select('event_name, event_number, id')
      .eq('event_id', eventID)
  }

  enteredEvents(subEvent: string) {
    return this.supabase
      .from('sub_events')
      .select('event_name, event_number')
      .eq('id', subEvent)
      .single()
  }

  authChanges(callback: (event: AuthChangeEvent, session: Session | null) => void) {
    return this.supabase.auth.onAuthStateChange(callback)
  }

  signUp(email: string, password: string) {
    return this.supabase.auth.signUp({ email, password, options: { emailRedirectTo: 'https://mosaic-sports.com/profile' } })
  }

  signIn(email: string) {
    return this.supabase.auth.signInWithOtp({ email })
  }

  signInPassword(email: string, password: string) {
    return this.supabase.auth.signInWithPassword({ email, password })
  }

  forgotPassword(email: string) {
    return this.supabase.auth.resetPasswordForEmail(email, { redirectTo: 'https://mosaic-sports.com/update-password' })
  }

  updatePassword(newPassword: string) {
    return this.supabase.auth.updateUser({ password: newPassword })
  }

  signOut() {
    return this.supabase.auth.signOut()
  }



  updateProfile(profile: Profile) {
    const update = {
      ...profile,
      updated_at: new Date(),
    }

    return this.supabase.from('user_data').upsert(update)
  }

  updateSocial(social: Social) {
    const update = {
      ...social,
      updated_at: new Date(),
    }

    return this.supabase.from('athlete_data').upsert(update)
  }

  updateCardData(cardData: CardData) {
    const update = {
      ...cardData,
      updated_at: new Date(),
    }

    return this.supabase.from('athlete_data').upsert(update)
  }

  updateCardDisplayData(cardDisplay: CardDisplay) {
    const update = {
      ...cardDisplay,
      updated_at: new Date(),
    }
    return this.supabase.from('athlete_data').upsert(update)
  }

  updateCardDataFromCard(user: boolean, idNum: string, value: number, newValue: string) {//0 name 1 pronunciation 2 nickname 3 line1 4 line2 5 team 6 mascot 7 coach 8 cardback
    var update
    var table = ""
    if (user) {
      table = "athlete_data"
    } else {
      table = "nonuser_athlete_data"
    }
    if (value == 0) {
      update = {
        id: idNum,
        name: newValue,
        updated_at: new Date()
      }
    }

    if (value == 1) {
      update = {
        id: idNum,
        pronunciation: newValue,
        updated_at: new Date()
      }
    }

    if (value == 2) {
      update = {
        id: idNum,
        nickname: newValue,
        updated_at: new Date()
      }
    }

    if (value == 3) {
      update = {
        id: idNum,
        line1: newValue,
        updated_at: new Date()
      }
    }

    if (value == 4) {
      update = {
        id: idNum,
        line2: newValue,
        updated_at: new Date()
      }
    }

    if (value == 5) {
      update = {
        id: idNum,
        team_name: newValue,
        updated_at: new Date()
      }
    }

    if (value == 6) {
      update = {
        id: idNum,
        team_mascot: newValue,
        updated_at: new Date()
      }
    }

    if (value == 7) {
      update = {
        id: idNum,
        coach: newValue,
        updated_at: new Date()
      }
    }

    if (value == 8) {
      update = {
        id: idNum,
        notes: newValue,
        updated_at: new Date()
      }
    }

    return this.supabase.from(table).upsert(update)
  }


  updateCardPhoto(cardPhoto: CardPhoto) {
    const update = {
      ...cardPhoto,
      updated_at: new Date(),
    }

    return this.supabase.from('athlete_data').upsert(update)
  }

  updateTeamData(teamData: TeamData) {
    const update = {
      ...teamData,
      updated_at: new Date(),
    }

    return this.supabase.from('athlete_data').upsert(update)
  }

  updateCompetitors(competitors: Competitors) {
    const update = {
      ...competitors,
      updated_at: new Date(),
    }

    return this.supabase.from('competitors').insert(update)
  }

  updateEnteredEvents(userID: string, event_id: string) {

    var update
    update = {
      id: event_id.concat(userID),
      sub_event: event_id,
      athlete_id: userID,
      edited_at: new Date()
    }
    return this.supabase.from('competitors').upsert(update)
  }

  updateEnteries(userID: string, event_id: string) {

    var update
    update = {
      id: event_id.concat(userID),
      sub_event: event_id,
      athlete_id: userID,
      edited_at: new Date()
    }
    return this.supabase.from('competitors').upsert(update)
  }

  generateTempAthlete(cardDisplay: CardDisplay) {
    const update = {
      ...cardDisplay,
      updated_at: new Date(),
    }

    return this.supabase.from('nonuser_athlete_data').insert(update).select('id').single()
  }

  addToEvent(nonuser_id: string, event_id: string, bib_number: string) {
    var update
    update = {
      id: event_id.concat(nonuser_id),
      sub_event: event_id,
      nonuser_id: nonuser_id,
      bib_number: bib_number,
      edited_at: new Date()
    }
    return this.supabase.from('competitors').upsert(update)
  }

  deleteEnteredEvent(ID: string) {
    return this.supabase.from('competitors').delete().eq('id', ID)
  }



  downloadCardPhoto(path: string) {
    return this.supabase.storage.from('cardPhotos').download(path)
  }


  uploadCardPhoto(filePath: string, file: File) {
    return this.supabase.storage.from('cardPhotos').upload(filePath, file)
  }

  saveEventRegistration(allEvents: any, allRelayEvents: any, registrar: any) {
    var update
    update= {
      name: registrar.name,
      email: registrar.email,
      phone: registrar.phone,
      events: allEvents,
      relayEvents: allRelayEvents,
      paid: true
    }
    return this.supabase
      .from('event_registrations')
      .insert(update);
  }

  saveTickets(friday: any, saturday: any, sunday: any, multiDay: any, registrar: any) {
    var update
    update= {
      name: registrar.name,
      email: registrar.email,
      phone: registrar.phone,
      friday: friday,
      saturday: saturday,
      sunday: sunday,
      multiDay: multiDay,
      paid: true
    }
    return this.supabase
      .from('event_tickets_temp')
      .insert(update);
  }

  downloadTickets(){
    return this.supabase
    .from('event_tickets_temp')
    .select('name, friday, saturday, sunday, multiDay')
  }

  downloadEventRegistrations(){
    return this.supabase
    .from('event_registrations')
    .select('events, relayEvents, name, paid, phone, email')
  }

  addSong(song: any){
    return this.supabase
    .from('songs')
    .upsert(song);
  }
}*/