import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommonModule, DatePipe, NgStyle, } from '@angular/common';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser'
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { A11yModule } from '@angular/cdk/a11y'
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { AuthSession } from '@supabase/supabase-js';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatBadgeModule } from '@angular/material/badge';
import { MatCardModule } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { EditCardDataComponent } from '../edit-card-data/edit-card-data.component';
import { AuthenticationService } from '@mosaicdash/services';
import { AthleteService } from '../../../services/athlete/athlete.service';
import { CardDisplay } from '../../../models/new-athlete/new-athlete.model';
import { ImageService } from '../../../services/image/image.service';
import { FormsModule } from '@angular/forms';
import { ImageCropperComponent } from '../image-cropper/image-cropper.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { URLHandlerService } from '../../../services/urlhandler/urlhandler.service';
import { Subscription } from 'rxjs';
import { CardTemplateComponent } from '../card-template/card-template.component';
import { MatSelectModule } from '@angular/material/select';
import { RoleSelectionDialogComponent } from '../role-selection-dialog/role-selection-dialog.component';


@Component({
  selector: 'app-athlete-card',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    A11yModule,
    MatBadgeModule,
    MatCardModule,
    FormsModule,
    ImageCropperComponent,
    MatProgressSpinnerModule,
    CardTemplateComponent,
    MatSelectModule,
    DatePipe
  ],
  templateUrl: './athlete-card.component.html',
  styleUrl: './athlete-card.component.css'
})
export class AthleteCardComponent implements OnInit, OnDestroy {
  hidden = false
  userIDBase64 = ""
  userLink = ""
  userURL = ""
  session: AuthSession | null = null;
  _cardPhotoUrl: SafeResourceUrl | undefined
  loading = false
  cardBack = false
  cardDisplays!: CardDisplay[]
  // Selected card to display
  selectedCard!: CardDisplay;
  commentator = true
  notes = false
  editable = false
  editID = ""
  section = "front";
  music = false
  isUploading: boolean = false;
  isLoading: boolean = false
  imageUrl: any = '';


  // State to toggle image editing
  isEditingImage: boolean = false;

  // Default avatar path
  defaultAvatar = '../../../../assets/images/profilePhoto.jpg';

  // Reference to ImageCropperComponent
  @ViewChild('imageCropper') imageCropper!: ImageCropperComponent;

  @Input() athleteID!: string


  // Subscription to handle observable
  private subscriptions = new Subscription();

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly athleteService: AthleteService,
    private readonly dom: DomSanitizer,
    private _snackBar: MatSnackBar,
    private readonly URLHandler: URLHandlerService,
    public dialog: MatDialog, // Add MatDialog to the constructor
    private readonly imageService: ImageService // Inject ImageService
  ) { }

  ngOnInit(): void {
    this.isLoading = true
    // Subscribe to session changes
    const sessionSub = this.authenticationService.session$.subscribe(session => {
      this.session = session;
      this.updateEditableStatus();
    });
    this.subscriptions.add(sessionSub);

    // Initialize component based on inputs
    this.initializeComponent();
    this.isLoading = false
  }

  ngOnDestroy(): void {
    // Unsubscribe to prevent memory leaks
    this.subscriptions.unsubscribe();
  }
  private initializeComponent(): void {
    this.loading=true
    if (this.athleteID) {
      const cardDisplaySub = this.athleteService.getCardDisplays(this.athleteID).subscribe({
        next: (cardDisplay) => {
          if (cardDisplay) {
            this.cardDisplays = cardDisplay; this.selectedCard=this.cardDisplays[0]
            this.loadCardPhoto();
            this.processUserID();
          }
        },
        error: (error) => {
          console.error('Error in initializeComponent:', error);
          this._snackBar.open('Failed to load card data.', 'Close', { duration: 3000 });
        }
      });
      this.subscriptions.add(cardDisplaySub);
    }
   this.loading=false
  }

  private async processUserID(): Promise<void> {
    try {
      const hexToBase64Result = await this.URLHandler.hexToBase64(this.athleteID);
      this.userIDBase64 = hexToBase64Result;

      const base64ToAthleteLinkResult = await this.URLHandler.base64ToAthleteLink(this.userIDBase64);

      this.userLink = base64ToAthleteLinkResult;
    } catch (error) {
      console.error('Error processing user ID:', error);
      this._snackBar.open('Failed to process user ID.', 'Close', { duration: 3000 });
    }
  }



  private async updateEditableStatus(): Promise<void> {//rewrite for roles
    if (this.athleteID) {
      //if (!this.sessionSent) {
        const sessionUserId = this.session?.user.id.replace(/[-]/g, "");
       // const targetUserId = this.athleteID.replace(/[-]/g, "");
        //this.editable = sessionUserId === targetUserId || sessionUserId === "0daed35ce08e44639134111315626bb1";

      this.editable = await this.athleteService.getEditable(this.athleteID, sessionUserId!)
      }

    if (this.editable) {
      this.editID = this.athleteID //|| this.tempID;
    } else {
      //this.placeholder = Array(9).fill(' ');
    }
  }

  openEditDialog(): void {
    this.selectedCard.id = this.athleteID
    this._snackBar.open('Editing is Temporarily Disabled.', 'Close', { duration: 3000 })
/*
    const dialogRef = this.dialog.open(EditCardDataComponent, {
      width: '390px',
      maxHeight: '100vh',
      minWidth: '390px',
      data: { cardDisplay: this.selectedCard, section: this.section }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.saveCardData(result);
      }
    });*/
  }


  saveCardData(updatedCardDisplay: CardDisplay): void {
    this.loading = true;
    this.athleteService.updateCardDisplayData(updatedCardDisplay).subscribe({
      next: () => {
        this.openSnackBar('Card updated successfully', 'Dismiss');
        // Optionally refresh data
      },
      error: (error) => {
        console.error('Error updating card:', error);
        this._snackBar.open('Failed to update card.', 'Close', { duration: 3000 });
      },
      complete: () => {
        this.loading = false;
      }
    });
  }



  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action);
  }

  reloadData(): void {
    this.athleteService.getCardDisplays(this.athleteID).subscribe({
      next: (cardDisplays) => {
        if (cardDisplays) {
          this.cardDisplays = cardDisplays;
          // If the previously selected card still exists, keep it selected
          if (this.selectedCard) {
            const existingCard = this.cardDisplays.find(card => card.id === this.selectedCard?.id);
            this.selectedCard = existingCard ? existingCard : this.cardDisplays[0];
          } else {
            this.selectedCard = this.cardDisplays[0];
          }
          this.loadCardPhoto();
        }
      },
      error: (error) => {
        console.error('Error reloading data:', error);
        this._snackBar.open('Failed to reload card data.', 'Close', { duration: 3000 });
      }
    });
  }

  // Handle file input change event
  fileChangeEvent(event: any): void {
    const file = event.target.files[0];
    if (file) {
      this.imageUrl = URL.createObjectURL(file);
      this.isEditingImage = true;
    }
  }


  loadCardPhoto(): void {
    if (this.selectedCard && this.selectedCard.imageUrl) {

      this._cardPhotoUrl = this.dom.bypassSecurityTrustResourceUrl(this.selectedCard.imageUrl
        //this.imageService.getImageUrl(this.cardDisplay.imageUrl)
      );
    } else {
      this._cardPhotoUrl = this.dom.bypassSecurityTrustResourceUrl(this.defaultAvatar);
    }
  }

  onImageError(event: Event) {
    const target = event.target as HTMLImageElement;
    target.src = this.defaultAvatar; // Replace with your default avatar URL
  }

  /**
  * Toggle the image editing mode.
  */
  toggleImageEdit() {
    this.isEditingImage = !this.isEditingImage;
    if (!this.isEditingImage) {
      // Reset any temporary data
      this.imageUrl = '';
    }
  }

  /**
   * Handle the cropped image file emitted from ImageCropperComponent.
   * @param file The cropped image file.
   */
  onImageCropped(file: File) {
    this.uploadCroppedImage(file);
  }

  /**
   * Handle the cropping cancellation event emitted from ImageCropperComponent.
   */
  onCroppingCanceled() {
    this.isEditingImage = false;
    this.imageUrl = '';
  }

  /**
   * Upload the cropped image using ImageService.
   * @param file The cropped image file.
   */
  uploadCroppedImage(file: File): void {
    this.imageService.uploadImage(file).subscribe({
      next: (uploadedUrl) => {
        this._cardPhotoUrl = this.dom.bypassSecurityTrustResourceUrl(uploadedUrl);
        this.updateImageUrlInDatabase(uploadedUrl);
        this.isEditingImage = false;
        this.isUploading = false; // Reset loading state
      },
      error: (err) => {
        console.error('Upload error:', err);
        this.isUploading = false; // Reset loading state
        this.openSnackBar('Failed to upload image', 'Dismiss');
      },
    });
  }

  /**
   * Update the image URL in the database.
   * @param uploadedUrl The URL of the uploaded image.
   */
  updateImageUrlInDatabase(uploadedUrl: string): void {
    this.athleteService.updateCardPhotoUrl(this.selectedCard.id, uploadedUrl).subscribe({
      next: () => {
        
        this.openSnackBar('Image updated successfully', 'Dismiss');
      },
      error: (error) => {
        console.error('Error updating image URL:', error);
        this.openSnackBar('Failed to update image', 'Dismiss');
      }
    });
  }


  /**
   * Save button handler to crop the image.
   */
  onSaveCrop(): void {
    if (this.isUploading) {
      // Prevent multiple uploads
      return;
    }
    if (this.imageCropper) {
      this.isUploading = true; // Set loading state
      this.imageCropper.cropImage();
    }
  }

  /**
   * Cancel button handler to cancel cropping.
   */
  onCancelCrop(): void {
    if (this.imageCropper) {
      this.imageCropper.cancelCrop();
    }
    this.isUploading = false; // Reset loading state
  }

  onSelectCard(card: CardDisplay): void {
    this.selectedCard = card;
    this.section = 'front'; // Reset to front section when switching cards
  }

  createAthlete(): void{
    const dialogRef = this.dialog.open(RoleSelectionDialogComponent, {
      width: '390px',
      maxHeight: '100vh',
      minWidth: '390px',
      disableClose: true, // Prevent closing the dialog by clicking outside
      data: {}, // Pass any initial data if needed
    });

    dialogRef.afterClosed().subscribe((result) => {
      const now = new Date();

      if (result) {
        // Handle the selected roles and additional data
        console.log('Selected Roles and Details:', result);
        // TODO: Implement your Supabase calls here to save the data
      } else {
        // Handle the case when the dialog was closed without selection
        console.log('Role selection was canceled or not completed.');
        // Optionally, log out the user or restrict access
      }

      // Update the lastPopupShownTime regardless of the result
      localStorage.setItem('lastPopupShownTime', now.toISOString());
    });

  }


}




