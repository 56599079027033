import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class URLHandlerService {

  constructor() { }



  async base64ToHex(base64String: string) {
    base64String = base64String.replace(/[-]/g,"+");
    base64String = base64String.replace(/[_]/g,"/");
    for (var i = 0, bin = atob(base64String.replace(/[ \r\n]+$/, "")), hex = []; i < bin.length; ++i) {
      var tmp = bin.charCodeAt(i).toString(16);
      if (tmp.length === 1) tmp = "0" + tmp;
      hex[hex.length] = tmp;
    }
    return hex.join("");
  }

  async hexToBase64(hexString: string) {
    const hexPairs = hexString.match(/\w{2}/g) || [];
    
    const binaryString = hexPairs
      .map(pair => String.fromCharCode(parseInt(pair, 16)))
      .join('');
  
    return btoa(binaryString);
  }

  async base64ToAthleteLink(base64String: string) {
    var athlete = "athlete/"
  base64String = base64String.replace(/[/]/g,"_");
  base64String = base64String.replace(/[+]/g,"-");
    return athlete.concat(base64String.replace(/=/g, ""));
  }

  async base64ToEventLink(base64String: string) {
    var athlete = "event/"
  base64String = base64String.replace(/[/]/g,"_");
  base64String = base64String.replace(/[+]/g,"-");
    return athlete.concat(base64String.replace(/=/g, ""));
  }

  async linkToURL(link: string) {
    var mosaic = "https://mosaic-sports.com/"
    return mosaic.concat(link)
  }

}
