import { Injectable } from '@angular/core';
import { SupabaseClient, AuthChangeEvent, Session, User, AuthSession } from '@supabase/supabase-js';
import { SupabaseService } from '../supabase/supabase.service';
import { BehaviorSubject, catchError, from, map, Observable, of, switchMap, throwError } from 'rxjs';
import { Role, UserRole, MosaicUser } from '@mosaicdash/models';


@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private supabase: SupabaseClient;
  private sessionSubject: BehaviorSubject<AuthSession | null> = new BehaviorSubject<AuthSession | null>(null);
  public session$ = this.sessionSubject.asObservable();

  constructor(private supabaseService: SupabaseService) {
    this.supabase = this.supabaseService.getClient();

    // Initialize session
    this.initializeSession();

    // Listen to auth state changes
    this.authChanges((event, session) => {
      this.sessionSubject.next(session);
      // Optionally, handle events like SIGNED_IN, SIGNED_OUT, etc.
    });
  }

  private async initializeSession() {
    const { data, error } = await this.supabase.auth.getSession();
    if (error) {
      console.error('Error initializing session:', error.message);
      this.sessionSubject.next(null);
    } else {
      this.sessionSubject.next(data.session);
    }
  }

  // Listen to authentication state changes
  authChanges(callback: (event: AuthChangeEvent, session: Session | null) => void) {
    return this.supabase.auth.onAuthStateChange(callback);
  }

  // Sign up a new user
  signUp(email: string, password: string) {
    return this.supabase.auth.signUp({
      email,
      password,
      options: { emailRedirectTo: 'https://mosaic-sports.com/profile' }, // Update as needed
    });
  }

  // Sign in with email and password
  signInPassword(email: string, password: string) {
    return this.supabase.auth.signInWithPassword({ email, password })
  }

  // Sign in using magic link (OTP)
  signInWithOtp(email: string) {
    return this.supabase.auth.signInWithOtp({ email });
  }

  // Reset password
  forgotPassword(email: string) {
    return this.supabase.auth.resetPasswordForEmail(email, {
      redirectTo: 'https://mosaic-sports.com/update-password', // Update as needed
    });
  }

  // Update password
  updatePassword(newPassword: string) {
    return this.supabase.auth.updateUser({ password: newPassword });
  }

  // Sign out
  signOut() {
    return this.supabase.auth.signOut();
  }

  // Get current session


  // Get current user
  async getCurrentUser(): Promise<User | null> {
    const { data } = await this.supabase.auth.getUser();
    return data.user;
  }

  // Retrieve access token on-demand
  async getAccessToken(): Promise<string | null> {
    const { data } = await this.supabase.auth.getSession();
    return data.session?.access_token || null;
  }

  getUserRole(): Observable<UserRole[]> {
    // Convert the getUser Promise to an Observable
    return from(this.supabase.auth.getUser()).pipe(
      switchMap((userResponse) => {
        const user = userResponse.data?.user;

        if (!user) {
          console.error('No authenticated user found.');
          return of([]);
        }
        // Query the database to check for the user's roles
        return from(
          this.supabase
            .from('user_roles')
            .select('* , roles(*)')
            .eq('user_id', user.id)
            .returns<UserRole[]>()
        ).pipe(
          map((response) => {
            if (response.error) {
              throw response.error;
            }
            return response.data as UserRole[];
          }),
          catchError((error) => {
            console.error('Error checking user roles:', error);
            return of([]);
          })
        );
      }),
      catchError((error) => {
        console.error('Error fetching user:', error);
        return of([]);
      })
    );
  }

  fetchUser(email: string): Observable<MosaicUser> {
    return from(this.supabase
      .from('user_data')
      .select('*')
      .eq('email', email)
      .returns<MosaicUser>()
    ).pipe(
      map(response => {
        if (response.error) {
          throw response.error;
        }
        return response.data as MosaicUser;
      }),
      catchError(error => {
        console.error('Error fetching user:', error);
        return throwError(() => new Error('Failed to fetch user.'));
      })
    );
  }

  async isAdmin(userId: string): Promise<boolean> {

    // Check if user has admin role from user_roles and roles tables
    const { data: adminRoleData, error: adminRoleError } = await this.supabase
      .from('user_roles')
      .select('roles(name)')
      .eq('user_id', userId)
      .eq('roles.name', 'admin') // Only fetch if role name is "admin"


    if (adminRoleError) {
      console.error('Error fetching admin role:', adminRoleError);
      return false;
    }

    // Determine if user has edit access directly or via admin role
    const isAdmin = !!adminRoleData;

    return isAdmin;
  }

}