<!-- src/app/components/role-selection-dialog/role-selection-dialog.component.html -->

<h1 mat-dialog-title>
  {{
    currentStep === 1 ? 'Select Your Roles' : 'Provide Additional Information'
  }}
</h1>

<div mat-dialog-content class="content">
  <!-- Step 1: Role Selection -->
  <form [formGroup]="rolesForm" *ngIf="currentStep === 1">
    <div formArrayName="roles">
      <div
        *ngFor="let roleCtrl of rolesFormArray.controls; let i = index"
        [matTooltip]="availableRoles[i].information"
        matTooltipPosition="left"
      >
        <mat-checkbox
          [formControlName]="i"
          
        >
          {{ availableRoles[i].name }}
          <span *ngIf="availableRoles[i].alreadyAssigned" style="color: grey"
            >(Already Assigned)</span
          >
        </mat-checkbox>
      </div>
    </div>
    <div *ngIf="rolesForm.invalid && rolesForm.touched" class="error">
      Please select at least one role.
    </div>
  </form>

  <!-- Step 2: Additional Data Collection -->
  <form [formGroup]="additionalDataForm" *ngIf="currentStep === 2">
    <div *ngFor="let role of selectedRoles">
      <h3>{{ role.name }}</h3>
      <div *ngFor="let field of roleDetailsMap[role.name]">
        <div [ngSwitch]="field.type">
          <!-- Text Input -->
          <div *ngSwitchCase="'text'">
            <mat-form-field appearance="fill" class="full-width">
              <mat-label>{{ field.label }}</mat-label>
              <input
                matInput
                [formControlName]="role.name + '_' + field.name"
              />
              <mat-error
                *ngIf="
                  additionalDataForm
                    .get(role.name + '_' + field.name)
                    ?.hasError('required')
                "
              >
                {{ field.label }} is required.
              </mat-error>
            </mat-form-field>
          </div>

          <!-- Select -->
          <div *ngSwitchCase="'select'">
            <mat-form-field appearance="fill" class="full-width">
              <mat-label>{{ field.label }}</mat-label>
              <mat-select [formControlName]="role.name + '_' + field.name">
                <mat-option value="">Select {{ field.label }}</mat-option>
                <!-- Use precomputed options -->
                <mat-option
                  *ngFor="let option of fieldOptionsMap[role.name][field.name]"
                  [value]="option.code"
                >
                  {{ option.name }}
                </mat-option>
              </mat-select>
              <!-- Loading Spinner -->
              <mat-progress-spinner
                *ngIf="
                  (field.name.toLowerCase().includes('state') &&
                    isLoadingStates) ||
                  (field.name.toLowerCase().includes('country') &&
                    isLoadingCountries)
                "
                diameter="20"
                mode="indeterminate"
              >
              </mat-progress-spinner>
              <!-- Error Messages -->
              <mat-error
                *ngIf="
                  additionalDataForm
                    .get(role.name + '_' + field.name)
                    ?.hasError('required')
                "
              >
                {{ field.label }} is required.
              </mat-error>
              <mat-error
                *ngIf="
                  (field.name.toLowerCase().includes('state') &&
                    loadStatesError) ||
                  (field.name.toLowerCase().includes('country') &&
                    loadCountriesError)
                "
              >
                Failed to load
                {{ field.name.includes('state') ? 'states' : 'countries' }}.
              </mat-error>
            </mat-form-field>
          </div>

          <!-- Date Picker -->
          <div *ngSwitchCase="'date'">
            <mat-form-field appearance="fill" class="full-width">
              <mat-label>{{ field.label }}</mat-label>
              <input
                matInput
                [matDatepicker]="picker"
                [formControlName]="role.name + '_' + field.name"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="picker"
              ></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
              <mat-error
                *ngIf="
                  additionalDataForm
                    .get(role.name + '_' + field.name)
                    ?.hasError('required')
                "
              >
                {{ field.label }} is required.
              </mat-error>
            </mat-form-field>
          </div>

          <!-- Multi-Select -->
          <div *ngSwitchCase="'multi-select'">
            <mat-form-field appearance="fill" class="full-width">
              <mat-label>{{ field.label }}</mat-label>
              <mat-select
                [formControlName]="role.name + '_' + field.name"
                multiple
              >
                <mat-option
                  *ngFor="let option of field.options"
                  [value]="option"
                  >{{ option }}</mat-option
                >
              </mat-select>
              <mat-error
                *ngIf="
                  additionalDataForm
                    .get(role.name + '_' + field.name)
                    ?.hasError('required')
                "
              >
                Please select at least one {{ field.label }}.
              </mat-error>
            </mat-form-field>
          </div>

          <!-- Default Case: Text Input -->
          <div *ngSwitchDefault>
            <mat-form-field appearance="fill" class="full-width">
              <mat-label>{{ field.label }}</mat-label>
              <input
                matInput
                [formControlName]="role.name + '_' + field.name"
              />
              <mat-error
                *ngIf="
                  additionalDataForm
                    .get(role.name + '_' + field.name)
                    ?.hasError('required')
                "
              >
                {{ field.label }} is required.
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <!-- Additional Handling for 'Parent' Role -->
        <div
          *ngIf="role.name === 'Parent' && field.name === 'create_number'"
          class="athlete-section"
        >
          <div formArrayName="Parent_athletes">
            <div
              *ngFor="
                let athlete of getAthletesArray('Parent').controls;
                let j = index
              "
              [formGroupName]="j"
              class="athlete-form-group"
            >
              <h4>Athlete {{ j + 1 }}</h4>
              <div *ngFor="let athleteField of roleDetailsMap['Athlete']">
                <div [ngSwitch]="athleteField.type">
                  <!-- Text Input -->
                  <div *ngSwitchCase="'text'">
                    <mat-form-field appearance="fill" class="full-width">
                      <mat-label>{{ athleteField.label }}</mat-label>
                      <input
                        matInput
                        formControlName="{{ athleteField.name }}"
                      />
                      <mat-error
                        *ngIf="
                          athlete.get(athleteField.name)?.hasError('required')
                        "
                      >
                        {{ athleteField.label }} is required.
                      </mat-error>
                    </mat-form-field>
                  </div>

                  <!-- Select -->
                  <div *ngSwitchCase="'select'">
                    <mat-form-field appearance="fill" class="full-width">
                      <mat-label>{{ athleteField.label }}</mat-label>
                      <mat-select formControlName="{{ athleteField.name }}">
                        <mat-option value=""
                          >Select {{ athleteField.label }}</mat-option
                        >
                        <!-- Use precomputed options -->
                        <mat-option
                          *ngFor="
                            let option of fieldOptionsMap['Athlete'][
                              athleteField.name
                            ]
                          "
                          [value]="option.code"
                        >
                          {{ option.name }}
                        </mat-option>
                      </mat-select>
                      <!-- Loading Spinner -->
                      <mat-progress-spinner
                        *ngIf="
                          (athleteField.name.toLowerCase().includes('state') &&
                            isLoadingStates) ||
                          (athleteField.name
                            .toLowerCase()
                            .includes('country') &&
                            isLoadingCountries)
                        "
                        diameter="20"
                        mode="indeterminate"
                      >
                      </mat-progress-spinner>
                      <!-- Error Messages -->
                      <mat-error
                        *ngIf="
                          athlete.get(athleteField.name)?.hasError('required')
                        "
                      >
                        {{ athleteField.label }} is required.
                      </mat-error>
                      <mat-error
                        *ngIf="
                          (athleteField.name.toLowerCase().includes('state') &&
                            loadStatesError) ||
                          (athleteField.name
                            .toLowerCase()
                            .includes('country') &&
                            loadCountriesError)
                        "
                      >
                        Failed to load
                        {{
                          athleteField.name.includes('state')
                            ? 'states'
                            : 'countries'
                        }}.
                      </mat-error>
                    </mat-form-field>
                  </div>

                  <!-- Date Picker -->
                  <div *ngSwitchCase="'date'">
                    <mat-form-field appearance="fill" class="full-width">
                      <mat-label>{{ athleteField.label }}</mat-label>
                      <input
                        matInput
                        [matDatepicker]="picker"
                        formControlName="{{ athleteField.name }}"
                      />
                      <mat-datepicker-toggle
                        matSuffix
                        [for]="picker"
                      ></mat-datepicker-toggle>
                      <mat-datepicker #picker></mat-datepicker>
                      <mat-error
                        *ngIf="
                          athlete.get(athleteField.name)?.hasError('required')
                        "
                      >
                        {{ athleteField.label }} is required.
                      </mat-error>
                    </mat-form-field>
                  </div>

                  <!-- Multi-Select -->
                  <div *ngSwitchCase="'multi-select'">
                    <mat-form-field appearance="fill" class="full-width">
                      <mat-label>{{ athleteField.label }}</mat-label>
                      <mat-select
                        formControlName="{{ athleteField.name }}"
                        multiple
                      >
                        <mat-option
                          *ngFor="let option of athleteField.options"
                          [value]="option"
                          >{{ option }}</mat-option
                        >
                      </mat-select>
                      <mat-error
                        *ngIf="
                          athlete.get(athleteField.name)?.hasError('required')
                        "
                      >
                        Please select at least one {{ athleteField.label }}.
                      </mat-error>
                    </mat-form-field>
                  </div>

                  <!-- Default Case: Text Input -->
                  <div *ngSwitchDefault>
                    <mat-form-field appearance="fill" class="full-width">
                      <mat-label>{{ athleteField.label }}</mat-label>
                      <input
                        matInput
                        formControlName="{{ athleteField.name }}"
                      />
                      <mat-error
                        *ngIf="
                          athlete.get(athleteField.name)?.hasError('required')
                        "
                      >
                        {{ athleteField.label }} is required.
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <hr />
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<div mat-dialog-actions>
  <!-- Step 1 Actions -->
  <ng-container *ngIf="currentStep === 1">
    <button class="button" mat-button (click)="cancel()">Cancel</button>
    <button
      class="button"
      mat-raised-button
      color="primary"
      (click)="proceedToAdditionalData()"
    >
      Next
    </button>
  </ng-container>

  <!-- Step 2 Actions -->
  <ng-container *ngIf="currentStep === 2">
    <button class="button" mat-button (click)="backToRoleSelection()">
      Back
    </button>
    <button
      class="button"
      mat-raised-button
      color="primary"
      (click)="submitAdditionalData()"
    >
      Submit
    </button>
  </ng-container>
</div>
