<!--@if(editable){
    <div class="editInfo">
    <h4>Click the  <mat-icon inline="true" class="editIcon">edit</mat-icon> next to the line you want to edit. Be sure to click the <mat-icon inline="true" class="editIcon">save</mat-icon> to save!</h4>
    </div> 
    {{openSnackBar()}}
}add card module or popup module-->

<mat-card class="card">
  @if(cardDisplays){
  <div class="card-selector" *ngIf="!isLoading">
    <mat-form-field appearance="fill" class="selector-field">
      <mat-label>Select Card</mat-label>
      <mat-select
        [(value)]="selectedCard"
        (selectionChange)="onSelectCard($event.value)"
      >
        <mat-option *ngFor="let card of cardDisplays" [value]="card">
          @if(card.season_id?.sport_id?.name==='Athlete'){ Default }@else {
          {{ card.team_id?.name }} -
          {{ card.season_id?.start_date | date : 'yyyy' }}-{{
            card.season_id?.end_date | date : 'yyyy'
          }}
          - {{ card.season_id?.sport_variation_id?.name }}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  @if(selectedCard&&!isLoading){
  <app-card-template
    [title]="
      selectedCard.name ? selectedCard.name : 'Click The Pencil To Edit!'
    "
    [subtitle]="selectedCard.pronunciation ? selectedCard.pronunciation : ''"
    [subsubtitle]="
      selectedCard.nickname ? '\u0022' + selectedCard.nickname + '\u0022' : ''
    "
    [image]="selectedCard.imageUrl || defaultAvatar"
    [showContent]="selectedCard ? true : false"
    [showImage]="(section.match('front') ? true : false) && !isEditingImage"
  >
    <div card-body>
      @if(section.match("front")){
      <p>
        <strong>{{ selectedCard.line1 }}</strong>
      </p>
      <p>
        <strong
          >{{ selectedCard.team_name }} {{ selectedCard.team_mascot }}</strong
        >
      </p>
      <p>
        <strong>{{ selectedCard.line2 }}</strong>
      </p>
      <p *ngIf="selectedCard.coach">
        <strong>Coached By {{ selectedCard.coach }}</strong>
      </p>
      }@else if (section.match("back")) {
      <p><strong>Notes</strong></p>
      @if(editable && !selectedCard.notes){
      <p>Click The Pencil to Add Notes!</p>
      }
      <p>{{ selectedCard.notes }}</p>
      } @else if (section.match("music")) {
      <p><strong>Music</strong></p>
      }

      <div *ngIf="isEditingImage" class="user-avatar">
        <!-- Use the ImageCropperComponent -->
        <app-image-cropper
          #imageCropper
          [imageUrl]="imageUrl"
          (imageCropped)="onImageCropped($event)"
          (croppingCanceled)="onCroppingCanceled()"
        ></app-image-cropper>
      </div>
    </div>
    <div card-actions>
      <mat-card-actions class="card-actions">
        <div class="button-grid-container">
          <div class="button-grid">
            @if(!isEditingImage){
            <div id="B1">
              @if(editable){
              <a
                id="edit-button"
                mat-icon-button
                color="primary"
                aria-label="Account"
                (click)="openEditDialog()"
              >
                <mat-icon>edit</mat-icon>
              </a>
              }
            </div>
            <div id="B2">
              @if(athleteID) {
              <a
                href="{{ userLink }}"
                id="profile"
                mat-icon-button
                color="primary"
                aria-label="Account"
                ><mat-icon> person</mat-icon></a
              >
              }<!--@else if(tempID){
                            <a href="profile" id="claimButton" mat-raised-button color="primary" aria-label="Account">
                                Claim
                                Your Profile!</a>
                            }-->
            </div><div id="B3">
            @if(music && editable){
            <a
              id="music"
              mat-icon-button
              color="primary"
              aria-label="Music"
              (click)="section = 'music'"
              ><mat-icon>music_note</mat-icon></a
            >
            }@else{
                <!--<a
                id="music"
                mat-icon-button
                color="primary"
                aria-label="Music"
                (click)="section = 'music'"
                ><mat-icon>qr_code</mat-icon></a>-->
           
            }
        </div>
            <!-- <a href="" id="B4" mat-icon-button color="primary" aria-label="Account"><mat-icon>shop</mat-icon></a>
                    <a href="" id="B5" mat-icon-button color="primary"
                        aria-label="Account"><mat-icon>event</mat-icon></a>-->
            <div id="B4"><!--<a
                id="music"
                mat-icon-button
                color="primary"
                aria-label="Music"
                (click)="section = 'music'"
                ><mat-icon>qr_code</mat-icon></a>--></div>
            <div id="B5"><!--<a
                id="music"
                mat-icon-button
                color="primary"
                aria-label="Music"
                (click)="section = 'music'"
                ><mat-icon>qr_code</mat-icon></a>--></div>
            <div id="B6">
              @if(selectedCard.notes || editable|| !section.match("front")) {
              @if(section.match("front")){
              <button
                matBadgePosition="above before"
                matBadgeSize="small"
                matBadge="!"
                matBadgeColor="accent"
                [matBadgeOverlap]="true"
                [matBadgeHidden]="selectedCard.notes || cardBack"
                (click)="section = 'back'; reloadData()"
                id="front"
                mat-icon-button
                color="primary"
                aria-label="Flip card"
              >
                <mat-icon> auto_stories</mat-icon>
              </button>
              } @else {
              <button
                matBadgePosition="above before"
                matBadgeSize="small"
                matBadge="!"
                matBadgeColor="accent"
                [matBadgeOverlap]="true"
                [matBadgeHidden]="selectedCard.notes || cardBack"
                (click)="section = 'front'; reloadData()"
                id="back"
                mat-icon-button
                color="primary"
                aria-label="Flip card"
              >
                <mat-icon> auto_stories</mat-icon>
              </button>
              } }
            </div>
            <div id="B7" *ngIf="editable">
              <button
                mat-icon-button
                color="primary"
                aria-label="Edit Image"
                id="photo"
                (click)="toggleImageEdit()"
              >
                <mat-icon>photo_camera</mat-icon>
              </button>
            </div>
            }
            <!-- Add Save and Cancel buttons when editing image -->
            @if(isEditingImage){

            <button
              mat-icon-button
              color="primary"
              (click)="onSaveCrop()"
              [disabled]="isUploading"
            >
              <mat-icon> save</mat-icon>
            </button>
            <button mat-icon-button color="warn" (click)="onCancelCrop()">
              <mat-icon> cancel</mat-icon>
            </button>
            <div *ngIf="isUploading" class="loading-indicator">
              <mat-spinner diameter="24"></mat-spinner>
            </div>
            }
          </div>
        </div>
      </mat-card-actions>
    </div>
  </app-card-template>
  }@else if(isLoading) {
  <div class="loading-container">
    <mat-progress-spinner mode="indeterminate" diameter="50">
    </mat-progress-spinner>
    <p>Loading Card...</p>
  </div>
  }@else { @if(editable){
  <div class="center-container">
    <div class="center">No Cards Yet!</div>
    <button
      mat-raised-button
      color="primary"
      (click)="createAthlete()"
      class="role-button"
    >
      Select Role
    </button>
  </div>
  <!--Make a blank Card TODO-->
  }@else {

  <div class="center-container">
    <div class="center">No Cards Yet!</div>
  </div>
  <!--Make a blank Card TODO-->
  } } } @else {
  <div class="loading-container">
    <mat-progress-spinner mode="indeterminate" diameter="50">
    </mat-progress-spinner>
    <p>Loading Data...</p>
  </div>
  }
</mat-card>

<!-- <mat-card class="card">
        <mat-card-header class="card-header">
            <mat-card-title>
                @if(editable && !cardDisplay.name){
                <h3>Click The Pencil To Edit!</h3>
                }
                <h3>{{cardDisplay.name}}</h3>
                <h4>{{cardDisplay.pronunciation}}</h4>
                <h4 *ngIf="cardDisplay.nickname">"{{cardDisplay.nickname}}"</h4>
                <h4 *ngIf="!cardDisplay.nickname"></h4>

            </mat-card-title>
        </mat-card-header>
        <mat-card-content class="card-body">
            @if(section.match("front")){
            <div class="image-container">
                <-- Display the image or the cropper based on edit mode ->
                <img *ngIf="!isEditingImage" [src]="_cardPhotoUrl || defaultAvatar" alt="Avatar" class="user-avatar"
                    (error)="onImageError($event)" />

                <div *ngIf="isEditingImage" class="user-avatar">
                    <-- Use the ImageCropperComponent ->
                    <app-image-cropper #imageCropper [imageUrl]="imageUrl" (imageCropped)="onImageCropped($event)"
                        (croppingCanceled)="onCroppingCanceled()"></app-image-cropper>
                </div>
            </div>


            <p><strong>{{cardDisplay.line1}}</strong></p>
            <p><strong>{{cardDisplay.team_name}} {{cardDisplay.team_mascot}}</strong></p>
            <p><strong>{{cardDisplay.line2}}</strong></p>
            <p *ngIf="cardDisplay.coach"><strong>Coached By {{cardDisplay.coach}}</strong></p>
            }@else if (section.match("back")) {
            <p><strong>Notes</strong></p>
            @if(editable && !cardDisplay.notes){
            <p>Click The Pencil to Add Notes!</p>
            }
            <p>{{cardDisplay.notes}}</p>}
            @else if (section.match("music")) {
            <p><strong>Music</strong></p>
            }
        </mat-card-content>
        <mat-card-actions class="card-actions">
            <div class="button-grid-container">
                <div class="button-grid">
                    @if(!isEditingImage){
                    <div id="B1">
                        @if(editable){
                        <a id="edit-button" mat-icon-button color="primary" aria-label="Account"
                            (click)="openEditDialog()">
                            <mat-icon>edit</mat-icon>
                        </a>
                        }
                    </div>
                    <div id="B2">
                        @if(athleteID)
                        {
                        <a href="{{userLink}}" id="profile" mat-icon-button color="primary"
                            aria-label="Account"><mat-icon>
                                person</mat-icon></a>
                        }@else if(tempID){
                        <a href="profile" id="claimButton" mat-raised-button color="primary" aria-label="Account"> Claim
                            Your Profile!</a>
                        }
                    </div>
                    @if(music && editable){
                    <a id="music" mat-icon-button color="primary" aria-label="Music"
                        (click)="section='music'"><mat-icon>music_note</mat-icon></a>
                    }@else{
                    <div id="B4"></div>
                    }
                    <-- <a href="" id="B4" mat-icon-button color="primary" aria-label="Account"><mat-icon>shop</mat-icon></a>
                <a href="" id="B5" mat-icon-button color="primary"
                    aria-label="Account"><mat-icon>event</mat-icon></a>->
                    <div id="B4"></div>
                    <div id="B5"></div>
                    <div id="B6">
                        @if(cardDisplay.notes || editable|| !section.match("front"))
                        {
                        @if(section.match("front")){
                        <button matBadgePosition="above before" matBadgeSize="small" matBadge="!" matBadgeColor="accent"
                            [matBadgeOverlap]=true [matBadgeHidden]="(cardDisplay.notes || cardBack)"
                            (click)="section='back'; reloadData();" id="front" mat-icon-button color="primary"
                            aria-label="Flip card"><mat-icon>
                                auto_stories</mat-icon></button>
                        } @else {
                        <button matBadgePosition="above before" matBadgeSize="small" matBadge="!" matBadgeColor="accent"
                            [matBadgeOverlap]=true [matBadgeHidden]="(cardDisplay.notes || cardBack)"
                            (click)="section='front'; reloadData();" id="back" mat-icon-button color="primary"
                            aria-label="Flip card"><mat-icon>
                                auto_stories</mat-icon></button>
                        }
                        }
                    </div>
                    <div id="B7" *ngIf="editable">
                        <button mat-icon-button color="primary" aria-label="Edit Image" (click)="toggleImageEdit()">
                            <mat-icon>photo_camera</mat-icon>
                        </button>
                    </div>
                    }
                    <-- Add Save and Cancel buttons when editing image ->
                    @if(isEditingImage){

                    <button mat-icon-button color="primary" (click)="onSaveCrop()" [disabled]="isUploading"><mat-icon>
                            save</mat-icon></button>
                    <button mat-icon-button color="warn" (click)="onCancelCrop()"><mat-icon>
                            cancel</mat-icon></button>
                    <div *ngIf="isUploading" class="loading-indicator">
                        <mat-spinner diameter="24"></mat-spinner>
                    </div>
                    }
                </div>
            </div>
        </mat-card-actions>
    </mat-card>
    }@else{
    <div>Card Not Found</div><--Make a blank Card TODO->
    }
</mat-card>
<-- Add more user information fields here as needed ->

<--<div class="card">
    <div class="card-header">

        <input [disabled]="!editing[0]" [cdkTrapFocusAutoCapture]="editing[0]" [cdkTrapFocus]="editing[0]"
            [ngStyle]="{'width.ch': Name.value ? Name.value.length+1: placeholder[0].length+1}" class="input-h2"
            matInput value={{cardDisplay.name}} #Name placeholder={{placeholder[0]}}>
        @if(editable){
        @if(!editing[0]){
        <button class="editButton" aria-hidden="false" mat-icon-button (click)="editing[0]=true" color="accent"
            aria-label="Edit icon">
            <mat-icon inline="false" class="editIcon">edit</mat-icon>
        </button>
        } @else if(editing[0]){
        <button class="editButton" aria-hidden="false" mat-icon-button (click)="save(0, Name.value);editing[0]=false"
            color="accent" aria-label="Save icon">
            <mat-icon inline="false" class="editIcon">save</mat-icon>
        </button>
        }
        }
        <br>




        <input [matTextPrefix]='""' [disabled]="!editing[1]" [cdkTrapFocusAutoCapture]="editing[1]"
            [cdkTrapFocus]="editing[1]"
            [ngStyle]="{'width.ch': Pronunciation.value ? Pronunciation.value.length+1: placeholder[1].length+1}"
            class="input-h3" matInput value={{cardDisplay.pronunciation}} #Pronunciation placeholder={{placeholder[1]}}>
        @if(editable){
        @if(!editing[1]){
        <button class="editButton" aria-hidden="false" mat-icon-button (click)="editing[1]=true" color="accent"
            aria-label="Edit icon">
            <mat-icon inline="false" class="editIcon">edit</mat-icon>
        </button>
        } @else if(editing[1]){
        <button class="editButton" aria-hidden="false" mat-icon-button
            (click)="save(1, Pronunciation.value);editing[1]=false" color="accent" aria-label="Save icon">
            <mat-icon inline="false" class="editIcon">save</mat-icon>
        </button>
        }
        }
        <br>



        @if(editable){
        <input [disabled]="!editing[2]" [cdkTrapFocusAutoCapture]="editing[2]" [cdkTrapFocus]="editing[2]"
            [ngStyle]="{'width.ch': Nickname.value ? Nickname.value.length+1: placeholder[2].length+1}" class="input-h3"
            matInput value={{cardDisplay.nickname}} #Nickname placeholder={{placeholder[2]}}>

        @if(!editing[2]){
        <button class="editButton" aria-hidden="false" mat-icon-button (click)="editing[2]=true" color="accent"
            aria-label="Edit icon">
            <mat-icon inline="false" class="editIcon">edit</mat-icon>
        </button>
        } @else if(editing[2]){
        <button class="editButton" aria-hidden="false" mat-icon-button
            (click)="save(2, Nickname.value);editing[2]=false" color="accent" aria-label="Save icon">
            <mat-icon inline="false" class="editIcon">save</mat-icon>
        </button>
        }
        }
        @if(!editable){
            @if(cardDisplay.nickname)
            {
        <h3>"{{cardDisplay.nickname}}"</h3>
            }@else {
                <br>
            }
        //TODO Chnage this to a way that detects if user added quotes and remove them so this can be the same
        }
    </div>



    <div class="card-body">



        @if(!cardBack && !notes){
        @if(_cardPhotoUrl){
        <img [src]="_cardPhotoUrl" alt="Avatar" class="avatar image" style="height: 150px; width: 150px" />
        }
        @if(!_cardPhotoUrl){
        <img src="../../../../assets/images/stickFigure.svg" alt="Stick Figure" class="user-avatar" color="#000000">
        }

        <input [disabled]="!editing[3]" [cdkTrapFocusAutoCapture]="editing[3]" [cdkTrapFocus]="editing[3]"
            [ngStyle]="{'width.ch': Event1.value ? Event1.value.length+1: placeholder[3].length+1}" class="input-p"
            matInput value={{cardDisplay.line1}} #Event1 placeholder={{placeholder[3]}}>
        @if(editable){
        @if(!editing[3]){
        <button class="editButton" aria-hidden="false" mat-icon-button (click)="editing[3]=true" color="accent"
            aria-label="Edit icon">
            <mat-icon inline="false" class="editIcon">edit</mat-icon>
        </button>
        } @else if(editing[3]){
        <button class="editButton" aria-hidden="false" mat-icon-button (click)="save(3, Event1.value);editing[3]=false"
            color="accent" aria-label="Save icon">
            <mat-icon inline="false" class="editIcon">save</mat-icon>
        </button>
        }
        }
        <br>

        <input [disabled]="!editing[4]" [cdkTrapFocusAutoCapture]="editing[4]" [cdkTrapFocus]="editing[4]"
            [ngStyle]="{'width.ch': Event2.value ? Event2.value.length+1: placeholder[4].length+1}" class="input-p"
            matInput value={{cardDisplay.line2}} #Event2 placeholder={{placeholder[4]}}>
        @if(editable){
        @if(!editing[4]){
        <button class="editButton" aria-hidden="false" mat-icon-button (click)="editing[4]=true" color="accent"
            aria-label="Edit icon">
            <mat-icon inline="false" class="editIcon">edit</mat-icon>
        </button>
        } @else if(editing[4]){
        <button class="editButton" aria-hidden="false" mat-icon-button (click)="save(4, Event2.value);editing[4]=false"
            color="accent" aria-label="Save icon">
            <mat-icon inline="false" class="editIcon">save</mat-icon>
        </button>
        }
        }
        <br>

        <input [disabled]="!editing[5]" [cdkTrapFocusAutoCapture]="editing[5]" [cdkTrapFocus]="editing[5]"
            [ngStyle]="{'width.ch': Team.value ? Team.value.length+1: placeholder[5].length+1}" class="input-p" matInput
            value={{cardDisplay.team_name}} #Team placeholder={{placeholder[5]}}>
        @if(editable){
        @if(!editing[5]){
        <button class="editButton" aria-hidden="false" mat-icon-button (click)="editing[5]=true" color="accent"
            aria-label="Edit icon">
            <mat-icon inline="false" class="editIcon">edit</mat-icon>
        </button>
        } @else if(editing[5]){
        <button class="editButton" aria-hidden="false" mat-icon-button (click)="save(5, Team.value);editing[5]=false"
            color="accent" aria-label="Save icon">
            <mat-icon inline="false" class="editIcon">save</mat-icon>
        </button>
        }
        }


        <input [disabled]="!editing[6]" [cdkTrapFocusAutoCapture]="editing[6]" [cdkTrapFocus]="editing[6]"
            [ngStyle]="{'width.ch': Mascot.value ? Mascot.value.length+1: placeholder[6].length+1}" class="input-p"
            matInput value={{cardDisplay.team_mascot}} #Mascot placeholder={{placeholder[6]}}>
        @if(editable){
        @if(!editing[6]){
        <button class="editButton" aria-hidden="false" mat-icon-button (click)="editing[6]=true" color="accent"
            aria-label="Edit icon">
            <mat-icon inline="false" class="editIcon">edit</mat-icon>
        </button>
        } @else if(editing[6]){
        <button class="editButton" aria-hidden="false" mat-icon-button (click)="save(6, Mascot.value);editing[6]=false"
            color="accent" aria-label="Save icon">
            <mat-icon inline="false" class="editIcon">save</mat-icon>
        </button>
        }
        }
        <br>
        @if(cardDisplay.coach){
        <span class="input-p ">Coached By </span>}<input [disabled]="!editing[7]" [cdkTrapFocusAutoCapture]="editing[7]"
            [cdkTrapFocus]="editing[7]"
            [ngStyle]="{'width.ch': Coach.value ? Coach.value.length+1: placeholder[7].length+1}" class="input-p"
            matInput value={{cardDisplay.coach}} #Coach placeholder={{placeholder[7]}}>
        @if(editable){
        @if(!editing[7]){
        <button class="editButton" aria-hidden="false" mat-icon-button (click)="editing[7]=true" color="accent"
            aria-label="Edit icon">
            <mat-icon inline="false" class="editIcon">edit</mat-icon>
        </button>
        } @else if(editing[7]){
        <button class="editButton" aria-hidden="false" mat-icon-button (click)="save(7, Coach.value);editing[7]=false"
            color="accent" aria-label="Save icon">
            <mat-icon inline="false" class="editIcon">save</mat-icon>
        </button>
        }
        }

        }@else if (cardBack && !notes) {

        //Add more user information fields here as needed
        <p><strong>Notes</strong>@if(editable){
            @if(!editing[8]){
            <button class="editButton" aria-hidden="false" mat-icon-button (click)="editing[8]=true" color="accent"
                aria-label="Edit icon">
                <mat-icon inline="false" class="editIcon">edit</mat-icon>
            </button>
            } @else if(editing[8]){
            <button class="editButton" aria-hidden="false" mat-icon-button
                (click)="save(8, Notes.value); editing[8]=false" color="accent" aria-label="Save icon">
                <mat-icon inline="false" class="editIcon">save</mat-icon>
            </button>
            }
            }
        </p>
        <textarea [disabled]="!editing[8]" [cdkTrapFocusAutoCapture]="editing[8]" [cdkTrapFocus]="editing[8]"
            class="input-p-text" matInput value={{cardDisplay.notes}} #Notes placeholder={{placeholder[8]}}></textarea>

        //Add more user information fields here as needed
        }
    </div>
   
</div>-->

<mat-menu #menu="matMenu">
  <button mat-menu-item>
    <mat-icon>dialpad</mat-icon>
    <span>Redial</span>
  </button>
  <button mat-menu-item disabled>
    <mat-icon>voicemail</mat-icon>
    <span>Check voice mail</span>
  </button>
  <button mat-menu-item>
    <mat-icon>notifications_off</mat-icon>
    <span>Disable alerts</span>
  </button>
</mat-menu>

<!--<div class="card" *ngIf="cardBack && !notes" (click)="commentator==true && notes=true"
    (click)="commentator==false && cardBack=false">
    <div class="card-header">
        <h2>{{cardDisplay.name}}</h2>
    </div>
    <div class="card-body">
        <img src="../../../../assets/images/stickFigure.svg" alt="Stick Figure" class="user-avatar" color="#000000">
        <p><strong>1600m PR:</strong> 4:08.72 <strong>SB:</strong> NA</p>
        <p><strong>Team:</strong> Shore AC</p>
        <p><strong>Graduation Year:</strong> 2024</p>
        Add more user information fields here as needed
    </div>
</div>
<div class="card" *ngIf="notes" (click)="cardBack=false; notes=false">
    <h2>Notes</h2>
    <p>Michael was a D3 All-American in the 800m for Elizabthtown College in 2016.</p>
</div>-->
