import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import Cropper from 'cropperjs';

@Component({
  selector: 'app-image-cropper',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './image-cropper.component.html',
  styleUrl: './image-cropper.component.css'
})
export class ImageCropperComponent  implements OnDestroy {
  @ViewChild('imageElement', { static: false }) imageElement!: ElementRef<HTMLImageElement>;
  @ViewChild('fileInput', { static: false }) fileInput!: ElementRef<HTMLInputElement>;

  @Input() imageUrl: string = ''; // Receives the image URL to display and crop
  @Output() imageCropped = new EventEmitter<File>();
  @Output() croppingCanceled = new EventEmitter<void>();

  cropper!: Cropper;

  ngOnDestroy() {
    this.destroyCropper();
  }

  /**
   * Trigger the hidden file input dialog.
   */
  triggerFileInput() {
    this.fileInput.nativeElement.click();
  }

  /**
   * Handle file selection from the file input.
   * @param event The file input change event.
   */
  onFileSelected(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      const reader = new FileReader();

      reader.onload = () => {
        this.imageUrl = reader.result as string;
      };

      reader.readAsDataURL(file);
    }
  }

  /**
   * Initialize the CropperJS instance.
   */
  initializeCropper() {
    // Ensure any existing Cropper instance is destroyed to prevent duplication
    this.destroyCropper();

    this.cropper = new Cropper(this.imageElement.nativeElement, {
      aspectRatio: 1,                    // Square aspect ratio
      dragMode: 'move',                  // Allow moving the image
      autoCropArea: 1,                   // Full area initially
      movable: true,                     // Allow image movement
      scalable: true,                    // Allow image scaling
      zoomable: true,                    // Allow zooming
      viewMode: 3,                       // Restrict the crop box to not exceed the canvas
      responsive: true,                  // Make the cropper responsive
      background: true,                  // Enable background
      guides: true,                      // Enable guides
      center: true,                      // Enable center indicator
      highlight: false,                  // Disable highlight
      cropBoxMovable: false,             // Fix the crop box position
      cropBoxResizable: false,           // Fix the crop box size
      toggleDragModeOnDblclick: false,   // Disable toggle on double-click
      minCropBoxWidth     : 150,
      minCropBoxHeight    : 150,
      minContainerHeight  : 150,
      minContainerWidth   : 150,
      minCanvasWidth      : 150,
      minCanvasHeight     : 150,
      ready: () => {
        console.log('Cropper is ready');
      },
    });
  
    console.log('Cropper instance:', this.cropper);
  }
  /**
   * Destroy the CropperJS instance if it exists.
   */
  destroyCropper() {
    if (this.cropper) {
      this.cropper.destroy();
      this.cropper = undefined!;
      console.log('Cropper instance destroyed');
    }
  }

  /**
   * Crop the image and emit the cropped file.
   */
  cropImage() {
    if (this.cropper) {
      const canvas = this.cropper.getCroppedCanvas({
        width: 375,
        height: 375,
        //this controls upload resolution.
        imageSmoothingEnabled: true,
        imageSmoothingQuality: 'high',
      });
      canvas.toBlob(
        (blob) => {
          if (blob) {
            const file = new File([blob], ' -image.jpeg', {
              type: 'image/jpeg',
            });
            this.imageCropped.emit(file);
          }
        },
        'image/jpeg',
        1
      );
    }
  }

  /**
   * Cancel the cropping process and emit an event.
   */
  cancelCrop() {
    this.croppingCanceled.emit();
    this.imageUrl = ''; // Reset the image URL to hide the cropper
    this.destroyCropper();
    if (this.fileInput && this.fileInput.nativeElement) {
      this.fileInput.nativeElement.value = ''; // Reset the file input
    }
  }
}