@if(showContent || isEditing){
<mat-card class="card mat-elevation-z6">
    <!-- Header -->
    <mat-card-header class="card-header">
        <mat-card-title [ngClass]="titleClass">{{ title }}</mat-card-title>
        <mat-card-subtitle class="black">{{ subtitle }}</mat-card-subtitle>
        <mat-card-subtitle class="black">{{ subsubtitle }}</mat-card-subtitle>
    </mat-card-header>

    <!-- Image (Optional) -->
    @if(image&&showImage){
    <img mat-card-image [src]="image" alt="Card image" class="user-avatar" [hidden]="showImage">
    }
    <!-- Content -->
    <mat-card-content class="card-body">
        <!-- Display Mode -->
        @if(!isEditing && showContent){
        <ng-container>
            <p>{{ description }}</p>
            <!-- Project additional content into 'card-body' slot -->
            <ng-content select="[card-body]"></ng-content>
        </ng-container>
        }
        <!-- Edit Mode -->
        @if(isEditing && formGroup){
        <ng-container>
            <form [formGroup]="formGroup" (ngSubmit)="formGroup ? null : null">
                <ng-content select="[card-form]"></ng-content>
            </form>
        </ng-container>
        }
    </mat-card-content>

    <!-- Actions -->
    <mat-card-actions class="card-actions">
        <!-- Project action buttons into 'card-actions' slot -->
        <ng-content select="[card-actions]"></ng-content>
    </mat-card-actions>
</mat-card>
}